html, body {
  height: 100vh;
  min-height: 100vh;
  background-color: #f6f6e0;
  font-family: intervogue;
  color: #203e2e!important;
}

@font-face {
  font-family: "intervogue";
  src: local("intervogue"),
    url("./fonts/intervogue.otf") format("opentype");
  font-display: swap;
}

.App {
  text-align: center;
}

.color-nav {
  background-color: #203e2e;
}

.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.Header-logo {
  display: flex; 
  align-items: left;
  padding: 10px 20px;
  height: 50px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Welcome-text {
  min-height: 325px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  color: black;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  color: white;
  gap: 10px;
}

.App-link {
  color: #61dafb;
  border: 1px solid #203e2e;
  border-radius: 100px;
  margin: 10px;
  padding: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
